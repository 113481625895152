export const generatePassword = (length) => {
    let pass = '';
    const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
            'abcdefghijklmnopqrstuvwxyz0123456789@#$';
      
    for (let i = 1; i <= length; i++) {
        const char = Math.floor(Math.random()
                    * str.length + 1);
          
        pass += str.charAt(char);
    }
      
    return pass;
}

export const generateUsername = (name, addRandomSuffix = false) => {
    let username = name.toLowerCase();
    username = username.replace(/[^a-z0-9_]/g, "");
    if (addRandomSuffix) {
        username = username + Math.floor(Math.random() * 100).toString();
    }
    return username;
};