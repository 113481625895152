import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { read, writeFile, utils } from "xlsx";
import { createSchool } from "../../api/apiHandler";
import FileDragAndDrop from "../Common/FileDragAndDrop";
import ConsoleOutput from "../Common/ConsoleOutput";
import { schoolColumnMapping } from "../Common/ColumnMappings";
import createBulkTeacher from "../UserListing/CreateBulkTeacher";
import createBulkDivision from "./CreateBulkDivision";
import createBulkStudent from "../UserListing/CreateBulkStudent";

export default function BulkCreate(props) {
    const [feedback, setFeedback] = useState([]);
    const [data, setData] = useState([]);
    const [fileDragAndDropKey, setFileDragAndDropKey] = useState(false);
    const addedDataList = useRef([]);
    const fileName = useRef(undefined);
    const { schoolId } = useParams();

    const resetFileDragAndDrop = () => {
        setFileDragAndDropKey((prevState) => !prevState);
    };

    const resetData = () => {
        setData([]);
        resetFileDragAndDrop();
    };

    const setAddedDataList = (object) => {
        addedDataList.current = [...addedDataList.current, object];
        return addedDataList.current;
    };

    const updateFeedback = (message, status = "info") => {
        setFeedback((feedback) => [
            ...feedback,
            { text: message, status: status },
        ]);
    };

    const generateXLSXOutput = () => {
        const worksheet = utils.json_to_sheet(addedDataList.current);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet);
        const outputFileName =
            fileName.current.replace(/\.[^/.]+$/, "") + "__output.xlsx";
        writeFile(workbook, outputFileName, { compression: true });
        console.log("Downloading", fileName.current);
    };

    const createSchoolApiCallLoop = () => {
        updateFeedback("Started executing process");
        updateFeedback("Mapping column names");

        const columnNamesMap = schoolColumnMapping(Object.keys(data[0]));

        let breakExecution = false;

        console.log(columnNamesMap);
        columnNamesMap.forEach((value, columnName) => {
            if (!value) {
                updateFeedback(`Unknown column name: ${columnName}`, "error");
                breakExecution = true;
            }
        });

        if (breakExecution) return;

        updateFeedback("Starting API calls");
        data.forEach((school, index) => {
            let payload = {};
            columnNamesMap.forEach((value, columnName) => {
                payload[value] = school[columnName];
            });

            payload = {
                ...payload,
                ar_name: payload.name,
                tu_name: payload.name,
                latitude: "0.0",
                longitude: "0.0",
                logo: "1655984251612school-profile.png",
                cover_image: "1655984339746highschool.jpeg",
            };

            console.log(payload);
            updateFeedback(`Adding school: ${payload.name}`);
            createSchool(payload).then((response) => {
                console.log(response);
                if (response.data.code == 1) {
                    updateFeedback(
                        `Added school: ${payload.name}; ID: ${response.data.data.school_id}`,
                        "success"
                    );
                } else {
                    updateFeedback(
                        `Failed adding school <${payload.name}> at row ${
                            index + 2
                        }; Message: ${response.data.message}`,
                        "error"
                    );
                }
            });
        });
        setData([]);
        resetFileDragAndDrop();
    };

    const handleExecuteProcess = (e) => {
        if (props.type === "school") {
            createSchoolApiCallLoop();
        } else if (props.type === "teacher") {
            createBulkTeacher({
                schoolId: schoolId,
                updateFeedback: updateFeedback,
                data: data,
                setAddedDataList: setAddedDataList,
                resetData: resetData,
            });
        } else if (props.type === "division") {
            createBulkDivision({
                schoolId: schoolId,
                updateFeedback: updateFeedback,
                data: data,
                setAddedDataList: setAddedDataList,
                resetData: resetData,
            });
        }
        else if (props.type === "student") {
            createBulkStudent({
                schoolId: schoolId,
                updateFeedback: updateFeedback,
                data: data,
                setAddedDataList: setAddedDataList,
                resetData: resetData,
            });
        }
    };

    const handleFileDrop = ([file]) => {
        setFeedback([]);
        addedDataList.current = [];
        fileName.current = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const wb = read(data);
            updateFeedback("File read successfully");
            const data_json = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {raw: false, defval: null});
            console.log(data_json);
            updateFeedback(
                "JSON data generated from file; Check browser console"
            );
            setData(data_json);
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card m-t-15">
                            <div className="header">
                                <h2>
                                    Bulk Create
                                    {props.type === "school" && <> School</>}
                                    {props.type === "teacher" && <> Teacher</>}
                                    {props.type === "division" && <> Division</>}                                    
                                    {props.type === "student" && <> Student</>}
                                </h2>
                            </div>
                            <div className="body">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-10">
                                        <FileDragAndDrop
                                            key={fileDragAndDropKey}
                                            onDrop={handleFileDrop}
                                            formats={["xlsx", "csv"]}
                                        />
                                        <div className="row my-4 ">
                                            <div className="col-7">
                                                <ConsoleOutput
                                                    messages={feedback}
                                                />
                                            </div>
                                            <div className="col text-right d-flex-inline flex-column">
                                                {data.length !== 0 && (
                                                    <button
                                                        className="btn btn-raised btn-primary waves-effect mb-4"
                                                        id="execute-process"
                                                        type="button"
                                                        onClick={
                                                            handleExecuteProcess
                                                        }
                                                    >
                                                        Execute process
                                                    </button>
                                                )}
                                                {addedDataList.current.length >
                                                    0 && (
                                                    <button
                                                        className="btn btn-raised btn-success waves-effect"
                                                        id="download-output"
                                                        type="button"
                                                        onClick={
                                                            generateXLSXOutput
                                                        }
                                                    >
                                                        Download Output File
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
