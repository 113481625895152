import React,{useEffect,useState} from 'react'
import { Link ,useParams,useNavigate} from "react-router-dom";
import {createSchool,editSchool,getSchooldDetails} from '../../api/apiHandler'
import $ from 'jquery'; 
import Alert from '../../components/Alert/Alert';
import {uploadSchoolImages,getMainBucketPath,getSchoolBucketPath} from '../../AWS/MyS3'
import S3 from 'react-aws-s3';

export default function CreateSchool(props) {

    window.Buffer = window.Buffer || require("buffer").Buffer;
    let navigate = useNavigate(); 
    const ReactS3Client = new S3(uploadSchoolImages());

    let id= useParams()

    const initalValue= {nameEN:"",nameAR:"",nameTU:"",email:"",countryCode:"",phoneNumber:"",webSite:"",location:""}
    const [formValues,setFormValues]= useState(initalValue)
    const [msg,setMsg] = useState (null);



    const showAlert = (msgDisplay,type) =>{
        setMsg({
            msg:msgDisplay,
            type:type
        })
    }

    const [selectedLogoFile, setSelectedLogoFile] = useState(null);
    const [selectedCovorFile, setSelectedCovorFile] = useState(null);

    const handleFileLogoInput = (e) => {
        props.isLoader(true)
        const file = e.target.files[0];
        console.log(file)
        const newFileName = new Date().getTime()+e.target.files[0].name;
        ReactS3Client
        .uploadFile(file, newFileName)
        .then(data => {
            console.log(data)
            props.isLoader(false)
            if (data.status === 204) {
                setSelectedLogoFile(newFileName)
              }
        })
        .catch(err =>  {
            props.isLoader(false)
            console.log("Error on s3")
            console.error(err)})
    }

    const handleFileCoverInput = (e) => {
        props.isLoader(true)
        const file = e.target.files[0];
        console.log(file)
        const newFileName = new Date().getTime()+e.target.files[0].name;
        ReactS3Client
        .uploadFile(file, newFileName)
        .then(data => {
            console.log(data)
            props.isLoader(false)
            if (data.status === 204) {
                setSelectedCovorFile(newFileName)
              }
        })
        .catch(err =>  {
            props.isLoader(false)
            console.log("Error on s3")
            console.error(err)})

    }


    const handleChange = (e)=>{
        if(e.target.value.trim()==""){
            e.target.value=e.target.value.trim()      
        }
        const {name,value} =e.target;
        setFormValues({...formValues,[name]:value});
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        console.log(formValues)
        if(props.type=="e"){
            updateApiCall(formValues)
        }else{
            createApiCall(formValues)
        }
    }

    const createApiCall = (initValues) =>{
        props.isLoader(true)
        console.log("Pass data request")
        console.log({"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"email":initValues.email,"country_code":initValues.countryCode,"phone_number":initValues.phoneNumber,"website_url":initValues.webSite,"location":initValues.location,"latitude":"0.0","longitude":"0.0","logo": (selectedLogoFile==null)? "":selectedLogoFile,"cover_image":(selectedCovorFile==null)? "":selectedCovorFile})
        createSchool({"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"email":initValues.email,"country_code":initValues.countryCode,"phone_number":initValues.phoneNumber,"website_url":initValues.webSite,"location":initValues.location,"latitude":"0.0","longitude":"0.0","logo": (selectedLogoFile==null)? "":selectedLogoFile,"cover_image":(selectedCovorFile==null)? "":selectedCovorFile}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
               // navigate(-1)
               navigate("/SchoolDetails/"+resposnse.data.data.school_id)
            }else{
                window.scrollTo(0,0);
                showAlert(resposnse.data.message,"danger")
            }
        });        
    }

    const updateApiCall = (initValues) =>{
        props.isLoader(true)
        console.log("Edit Api call")
        console.log({"school_id":id.schoolId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"email":initValues.email,"country_code":initValues.countryCode,"phone_number":initValues.phoneNumber,"website_url":initValues.webSite,"location":initValues.location,"latitude":"0.0","longitude":"0.0","logo": (selectedLogoFile==null)? "":selectedLogoFile,"cover_image":(selectedCovorFile==null)? "":selectedCovorFile})
        editSchool({"school_id":id.schoolId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"email":initValues.email,"country_code":initValues.countryCode,"phone_number":initValues.phoneNumber,"website_url":initValues.webSite,"location":initValues.location,"latitude":"0.0","longitude":"0.0","logo": (selectedLogoFile==null)? "":selectedLogoFile,"cover_image":(selectedCovorFile==null)? "":selectedCovorFile}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                window.scrollTo(0,0);
                showAlert(resposnse.data.message,"danger")
            }
        });       
    } 

    useEffect(() => {
        let mounted = true;
        if(props.type=="e"){
            console.log("Edit")
            getSchooldDetails({"school_id":id.schoolId}).then((resposnse)=>{
                console.log(resposnse)
                if(resposnse.data.code==1){   
                    var data={nameEN:resposnse.data.data.name,nameAR:resposnse.data.data.ar_name,nameTU:resposnse.data.data.tu_name,email:resposnse.data.data.email,countryCode:resposnse.data.data.country_code,phoneNumber:resposnse.data.data.phone_number,webSite:resposnse.data.data.website_url,location:resposnse.data.data.location}                               
                    setFormValues(data);
                    let logo=resposnse.data.data.logo.replace(getMainBucketPath(),"").replace(getSchoolBucketPath(),"")
                    let coverImage=resposnse.data.data.cover_image.replace(getMainBucketPath(),"").replace(getSchoolBucketPath(),"")
                    $('#schoolLogo').next(".custom-file-label").html(logo);
                    $('#schoolCover').next(".custom-file-label").html(coverImage);

                    if(!(logo ==="")){
                        $('#schoolLogo').prop('required',false);
                    }
                    if(!(coverImage ==="")){
                        $('#schoolCover').prop('required',false);
                    }

                    setSelectedLogoFile(logo)
                    setSelectedCovorFile(coverImage)
                }else{
                    navigate(-1)
                }
            });        
        }
        return () => mounted = false;
    }, []);

    $(document).ready(function() {
        $('input[type="file"]').on("change", function() {
          let filenames = [];
          let files = this.files;
          if (files.length > 1) {
            filenames.push("Total Files (" + files.length + ")");
          } else {
            for (let i in files) {
              if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
              }
            }
          }
          $(this)
            .next(".custom-file-label")
            .html(filenames.join(","));
        });
      });
      
  return (
    <section className="content">
    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>{ (props.type == "e") ? "Edit School" : "Create School"}</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#" >
                        <label htmlFor="name_en">Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text" placeholder='Name' className="form-control" id="nameEN" name="nameEN" required value={formValues.nameEN} onChange={handleChange} />
                                </div>
                            </div>
                            <label htmlFor="name_ar">Name Arabic</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text" placeholder='Name Arabic' className="form-control" id="nameAR" name="nameAR" required value={formValues.nameAR} onChange={handleChange}/>
                                </div>
                            </div>
                            <label htmlFor="name_tu">Name Turkish</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text" placeholder='Name Turkish' className="form-control" id="nameTU" name="nameTU" required value={formValues.nameTU} onChange={handleChange} />
                                </div>
                            </div>
                            <label htmlFor="school_email">Email</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="email" placeholder='Email' className="form-control" id="email" name="email" required value={formValues.email} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="row clearfix">
                                <div className="col-md-2">
                                <label htmlFor="school_country_code">Country Code</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="tel" placeholder='Country Code' className="form-control" id="countryCode" name="countryCode" required value={formValues.countryCode} onChange={handleChange} />
                                </div>
                            </div>
                                </div>

                                <div className="col-md-10">
                                <label htmlFor="school_phone_number">Phone Number</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="tel" placeholder='Phone Number' className="form-control"  id="phoneNumber" name="phoneNumber" required value={formValues.phoneNumber} onChange={handleChange} />
                                </div>
                            </div>

                                </div>

                            </div>

                            <label htmlFor="school_website">Website</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="url" placeholder='Website' className="form-control" id="webSite" name="webSite" required value={formValues.webSite} onChange={handleChange}/>
                                </div>
                            </div>
                            <label htmlFor="school_location">Location</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <textarea  placeholder='Location' cols="30" rows="5" className="form-control no-resize" id="location" name="location" required value={formValues.location} onChange={handleChange}></textarea>
                                </div>
                            </div>

                            <label htmlFor="school_log">Upload Logo</label>
                            <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" name="files[]"  className="custom-file-input form-control" id="schoolLogo" required onChange={handleFileLogoInput} accept="image/*"/>
                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                    </div>
                            </div>

                            <label htmlFor="school_log">Upload Cover Image</label>
                            <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" name="filesCover[]"  className="custom-file-input form-control" id="schoolCover" required onChange={handleFileCoverInput} accept="image/*"/>
                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                    </div>
                            </div>
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
