import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPermissions } from "../../../api/apiHandler";
import { logOutRedirectCall } from "../../../pages/Common/RedirectPathMange";

export default function SideBar() {
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState([]);
  const [staticpages, setStaticPages] = useState('');
  const [staticelement, setStaticElement] = useState('');
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    getPermissions({}).then((response) => {
      if (response.data.code == 1) {
        let data = response.data.data.map((item) => item?.admin_menu_id);

        setUserRole(data);
      }
    });
    setUserData(JSON.parse(localStorage.getItem("userData")));
    let data = JSON.parse(localStorage.getItem("userData")).permission.map(
      (item) => item?.admin_menu_id
    );

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const onClickStatic = () => {
    if(staticpages === 'mainstatic') {
      return (
        <ul className="">
          <li>
            <a href={void 0} className="menu-toggle" onClick={() => setStaticElement(staticelement === 'privacypolicy' ? '' : 'privacypolicy')}>
              <i className="zmdi zmdi-file-text"></i>
              <span>Privacy &amp; Policy</span>{" "}
            </a>
            { staticelement === 'privacypolicy' ? <ul className="">
              <li>
                <Link to="/PrivacyPolicyE">English</Link>{" "}
              </li>
              <li>
                <Link to="/PrivacyPolicyA">Arabic</Link>{" "}
              </li>
              <li>
                <Link to="/PrivacyPolicyT">Turkish</Link>{" "}
              </li>
            </ul> : ""}
          </li>
          <li>
            <a href={void 0} className="menu-toggle" onClick={() => setStaticElement(staticelement === 'aboutus' ? '' : 'aboutus')}>
              <i className="zmdi zmdi-file-text"></i>
              <span>About Us</span>{" "}
            </a>
            { staticelement === 'aboutus' ? <ul className="">
              <li>
                <Link to="/AboutUsE">English</Link>{" "}
              </li>
              <li>
                <Link to="/AboutUsA">Arabic</Link>{" "}
              </li>
              <li>
                <Link to="/AboutUsT">Turkish</Link>{" "}
              </li>
            </ul> : ""}
          </li>
          <li>
            <a href={void 0} className="menu-toggle" onClick={() => setStaticElement(staticelement === 'termscondition' ? '' : 'termscondition')}>
              <i className="zmdi zmdi-file-text"></i>
              <span>Terms &amp; Conditions</span>{" "}
            </a>
            { staticelement === 'termscondition' ? <ul className="">
              <li>
                <Link to="/TermsConditionsE">English</Link>{" "}
              </li>
              <li>
                <Link to="/TermsConditionsA">Arabic</Link>{" "}
              </li>
              <li>
                <Link to="/TermsConditionsT">Turkish</Link>{" "}
              </li>
            </ul> : ""}
          </li>
          <li>
            <Link to="/FAQListing">
              <i className="zmdi zmdi-file-text"></i>
              <span>FAQ</span>
            </Link>
          </li>
        </ul>
      )
    }
  }

  return (
    <aside id="leftsidebar" className="sidebar">
      <div className="user-info">
        <div className="info-container">
          <div className="name" data-toggle="dropdown">
            {localStorage.getItem("name")}
          </div>
          <div className="email">{localStorage.getItem("email")}</div>
        </div>
      </div>
      <div className="menu">
        <ul className="list">
          <li>
            <Link to="/">
              <i className="zmdi zmdi-delicious"></i>
              <span>Dashboard</span>{" "}
            </Link>{" "}
          </li>
          {/* {(userData?.role == "admin" || userRole.includes(1)) && ( */}
            <li>
              <a href={void 0} className="menu-toggle" onClick={() => setStaticPages((staticpages === 'manageusers' || staticpages === 'mainstatic') ? '' : 'manageusers')}>
                <i className="zmdi zmdi-accounts-alt"></i>
                <span>Manage Users</span>{" "}
              </a>
              { staticpages === 'manageusers' ? <ul className="">
                <li>
                  <Link to="/usersListingTeacher">Teacher</Link>{" "}
                </li>
                <li>
                  <Link to="/usersListingStudent">Student</Link>{" "}
                </li>
              </ul> : ''}
            </li>
          {/* )} */}
          {(userData?.role == "admin" || userRole.includes(2)) && (
            <li>
              <Link to="/SchoolManagementListing">
                <i className="zmdi zmdi-city-alt"></i>
                <span>Manage School/Division</span>
              </Link>
            </li>
          )}
          {/* {(userData?.role == "admin" || userRole.includes(3)) && (
            <li>
              <Link to="/managePredefinedClassListing">
                <i className="zmdi zmdi-city-alt"></i>
                <span>Manage Predefined Class</span>
              </Link>
            </li>
          )} */}
          {userData?.role == "admin" && (
            <li>
              <Link to="/PerformanceScoreManage">
                <i className="zmdi zmdi-accounts-alt"></i>
                <span>Performance Score </span>
              </Link>
            </li>
          )}
          {userData?.role == "admin" && (
            <li>
              <Link to="/adminManage">
                <i className="zmdi zmdi-accounts-alt"></i>
                <span>Manage Admin</span>
              </Link>
            </li>
          )}
          {(userData?.role == "admin" || userRole.includes(5)) && (
            <li>
              <Link to="/funZoneManage">
                <i className="zmdi zmdi-filter-b-and-w"></i>
                <span>Fun Zone</span>
              </Link>
            </li>
          )}
          {(userData?.role == "admin" || userRole.includes(6)) && (
            <li>
              <Link to="/blockWordListing">
                <i className="zmdi zmdi-block-alt"></i>
                <span>Block Word</span>
              </Link>
            </li>
          )}
          {(userData?.role == "admin" || userRole.includes(7)) && (
            <li>
              <Link to="/avatarListing">
                <i className="zmdi zmdi-filter-b-and-w"></i>
                <span>Avatar</span>
              </Link>
            </li>
          )}
          {(userData?.role == "admin" || userRole.includes(8)) && (
            <li>
              <Link to="/NotificationListing">
                <i className="zmdi zmdi-notifications-add"></i>
                <span>Notifications</span>
              </Link>
            </li>
          )}
          {(userData?.role == "admin" || userRole.includes(9)) && (
            <li>
              {" "}
              <a href={void 0} className="menu-toggle" onClick={() => { setStaticPages(staticpages ? '' : 'mainstatic'); setStaticElement(''); }}>
                <i className="zmdi zmdi-collection-text"></i>
                <span>Manage Static Page</span>{" "}
              </a>
              {onClickStatic()}
              {/* <ul className="ml-menu">
                <li>
                  <a href={void 0} className="menu-toggle">
                    <i className="zmdi zmdi-file-text"></i>
                    <span>Privacy &amp; Policy</span>{" "}
                  </a>
                  <ul className="ml-menu">
                    <li>
                      <Link to="/PrivacyPolicyE">English</Link>{" "}
                    </li>
                    <li>
                      <Link to="/PrivacyPolicyA">Arabic</Link>{" "}
                    </li>
                    <li>
                      <Link to="/PrivacyPolicyT">Turkish</Link>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={void 0} className="menu-toggle">
                    <i className="zmdi zmdi-file-text"></i>
                    <span>About Us</span>{" "}
                  </a>
                  <ul className="ml-menu">
                    <li>
                      <Link to="/AboutUsE">English</Link>{" "}
                    </li>
                    <li>
                      <Link to="/AboutUsA">Arabic</Link>{" "}
                    </li>
                    <li>
                      <Link to="/AboutUsT">Turkish</Link>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={void 0} className="menu-toggle">
                    <i className="zmdi zmdi-file-text"></i>
                    <span>Terms &amp; Conditions</span>{" "}
                  </a>
                  <ul className="ml-menu">
                    <li>
                      <Link to="/TermsConditionsE">English</Link>{" "}
                    </li>
                    <li>
                      <Link to="/TermsConditionsA">Arabic</Link>{" "}
                    </li>
                    <li>
                      <Link to="/TermsConditionsT">Turkish</Link>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/FAQListing">
                    <i className="zmdi zmdi-file-text"></i>
                    <span>FAQ</span>
                  </Link>
                </li>
              </ul> */}
            </li>
          )}
          
            <li>
              <Link to="/ChangePassword">
                <i className="zmdi zmdi-filter-b-and-w"></i>
                <span>Change Password</span>
              </Link>
            </li>
          

          <li>
            {" "}
            <a
              onClick={() => {
                logOutRedirectCall();
              }}
            >
              <i className="zmdi zmdi-power-off"></i>
              <span>Sign Out</span>{" "}
            </a>{" "}
          </li>

          
            <li>
              <div id="google_translate_element"></div>
            </li>
          
        </ul>
      </div>
    </aside>
  );
}
