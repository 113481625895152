import React, { useState ,useEffect} from 'react'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { Link ,useNavigate} from 'react-router-dom'
import {getSchoolList,deleteSchool,updateSchoolStatus} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import { renderToStaticMarkup } from 'react-dom/server'


export default function SchoolManagementListing(props) {
    const [schoolList, setSchoolList] = useState(null)
    let navigate = useNavigate(); 
    const [msg,setMsg] = useState (null);
    const showAlert = (msgDisplay,type) =>{
        setMsg({
            msg:msgDisplay,
            type:type
        })
    }

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)

        getSchoolList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            if(resposnse.data.code==1){
                setSchoolList(resposnse.data.data.list);
            }else{
                setSchoolList([]);
            }
        });
    
        return () => mounted = false;
    }, []);

    const redirectCreateSchool = ()=>{
        let path ="/createSchool/";
        navigate(path)
    }

    const redirectBulkCreateSchool = ()=>{
        let path ="/bulkCreateSchool/";
        navigate(path)
    }


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  processing: true,
                  order: [[0, 'desc']],
                  dom: 'Bfrtip',
                      buttons: ['copy','csv','print'],
                      "bDestroy": true
            }
        );
    });

    function deleteSchoolIdWise(schoolId){
        console.log("Call Delete"+schoolId)
        if(window.confirm("Are you sure you want to delete?")){
            deleteSchool({"school_id":schoolId}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    window.scrollTo(0,0);
                    $('#mangeUser').DataTable().row("#studentId"+schoolId).remove().draw();
                    showAlert(resposnse.data.message,"success")
                }else{
                    window.scrollTo(0,0);
                showAlert(resposnse.data.message,"danger")
                }
            });
        }
    } 

    const statusChangeListner = (position)=>{

        console.log(position) 
 
        props.isLoader(true)
 
        console.log("Call api change status")
 
        let apiData={}
        if(schoolList[position].is_active===1){
             apiData={"school_id":schoolList[position].id,"status":0}        
        }
        else{
             apiData={"school_id":schoolList[position].id,"status":1}        
        }
 
        console.log(apiData)
 
        updateSchoolStatus(apiData).then((resposnse)=>{
         console.log(resposnse)
         props.isLoader(false)
         if(resposnse.data.code==1){
             callAfterApiChangeStatus(position,true)
         }else{
             callAfterApiChangeStatus(position,false)
         }
         })    
     }
 
     const callAfterApiChangeStatus = (position,status)=>{
 
         if(status){
 
             if(schoolList[position].is_active===1){
                 schoolList[position].is_active=0       
                }else{
                 schoolList[position].is_active=1
                 }
         }
 
         setSchoolList(schoolList)
 
         manageCheckStatus(position)
 
     }
 
     const manageCheckStatus = (position)=>{
        var item=schoolList[position];
 
        console.log(position) 
  
         var data =renderToStaticMarkup(renderStatusUiText(schoolList[position]));
         $('#mangeUser').DataTable().row("#studentId"+item.id).cell("#changeStatus"+item.id).data(data).draw(false);
  
     }
 
     const renderStatusUiText=(item)=>{
         return (
             item.is_active===1 ?
             <>
             <span class="badge badge-success">Active</span>
             </>
             :
             <>                                                
             <span class="badge badge-danger">Inactive</span>
             </>
 
         )
     }
 
    if(schoolList==null) return null;
    props.isLoader(false)
    return (
    <section className="content home">
        <Heading title="Mange School/Division"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-warning m-t-15 m-b-15 ml-2 waves-effect right" onClick={redirectBulkCreateSchool}>Bulk Create</button>
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateSchool}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>School Email Id</th>
                                    <th>School Location</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                   <th>Id</th>
                                    <th>Name</th>
                                    <th>School Email Id</th>
                                    <th>School Location</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>

                            {
                               <tbody>                               
                            
                               {schoolList.map((item,index)=>{ 
                                    return (<tr id={"studentId"+item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.location}</td>
                                        <td id={"changeStatus"+item.id} className="text-center" > 
                                             {
                                                item.is_active===1 ?
                                                <>
                                                <span class="badge badge-success">Active</span>
                                                </>
                                                :
                                                <>                                                
                                                <span class="badge badge-danger">Inactive</span>
                                                </>
                                             }
                                            </td>
                                            <td>
                                            <i onClick={()=>statusChangeListner(index)} className="zmdi zmdi-refresh-alt"> </i>
                                            </td>
                                        <td className="text-right">
                                            <div className='action-btn'>                        
                                                <Link to={`/SchoolDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                                <Link to={`/editSchool/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                                <a onClick={()=>deleteSchoolIdWise(item.id)}  className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                    </td>
                                     </tr>);                         
                                   })}
                               </tbody>
                                
                            }
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
