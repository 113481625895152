import React,{useEffect,useState}from 'react'
import { Link ,useParams,useNavigate} from "react-router-dom";
import {createDevision,getClassList,editDevision,getDevisionDetails,getUserList} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import Select from 'react-select';
import {uploadDivisionCoverImages,uploadDivisionLogoImages,getMainBucketPath,getDivisionLogoPath,getDivisionCoverPath} from '../../AWS/MyS3'
import S3 from 'react-aws-s3';
import $ from 'jquery'; 

export default function CreateDevision(props) {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const ReactS3Client = new S3(uploadDivisionLogoImages());
    const ReactS3ClientCover = new S3(uploadDivisionCoverImages());

    let id =  useParams()
    
    let navigate = useNavigate(); 
    let selectClassIdValue=""

    const initalValue= {nameEN:"",nameAR:"",nameTU:""}
    const [formValues,setFormValues]= useState(initalValue)

    const [msg,setMsg] = useState (null);
    const [myClassList, setClasslList] = useState(null)
    const [myTeachertist, setTeacherList] = useState(null)

    const [selectedOptionClass, setSelectedOptionClass] = useState(null);
    const [selectedTeacher, setSelectedTeacher] = useState([]);
    const [selectedClassTeacher, setSelectedClassTeacher] = useState([]);

    const [selectedLogoFile, setSelectedLogoFile] = useState(null);
    const [selectedCovorFile, setSelectedCovorFile] = useState(null);


    const handleFileLogoInput = (e) => {
        props.isLoader(true)
        const file = e.target.files[0];
        console.log(file)
        const newFileName = new Date().getTime()+e.target.files[0].name;
        ReactS3Client
        .uploadFile(file, newFileName)
        .then(data => {
            console.log(data)
            props.isLoader(false)
            if (data.status === 204) {
                setSelectedLogoFile(newFileName)
              }
        })
        .catch(err =>  {
            props.isLoader(false)
            console.log("Error on s3")
            console.error(err)})
    }

    const handleFileCoverInput = (e) => {
        props.isLoader(true)
        const file = e.target.files[0];
        console.log(file)
        const newFileName = new Date().getTime()+e.target.files[0].name;
        ReactS3ClientCover
        .uploadFile(file, newFileName)
        .then(data => {
            console.log(data)
            props.isLoader(false)
            if (data.status === 204) {
                setSelectedCovorFile(newFileName)
              }
        })
        .catch(err =>  {
            props.isLoader(false)
            console.log("Error on s3")
            console.error(err)})

    }


    const showAlert = (msgDisplay,type) =>{
        setMsg({
            msg:msgDisplay,
            type:type
        })

    }
    
    let schoolId=0
    let devisionId=0
    
    if(props.type=="e"){
        let data=id.schoolIdDivID.split("&")
       schoolId=data[0]
       devisionId=data[1]
       console.log(data)
    }else{
        schoolId=id.schoolId
    }



    const handleClassChange =(data)=>{
        console.log(data)
        if(selectedOptionClass !==data){
            setSelectedOptionClass(data)
        }
    }
    const handleTeacherChange=(data)=>{
        console.log(data)
        setSelectedTeacher(data)
    }
    const handleClassTeacherChange=(data)=>{
        console.log(data)
        setSelectedClassTeacher(data)
    }

    const handleChange = (e)=>{
        if(e.target.value.trim()==""){
            e.target.value=e.target.value.trim()      
        }
        const {name,value} =e.target;
        setFormValues({...formValues,[name]:value});
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        console.log(formValues)
        if(props.type=="e"){
            updateApiCall(formValues)
        }else{
            createApiCall(formValues)
        }

    }

    const createApiCall = (initValues) =>{
        props.isLoader(true)
        let classId=selectedOptionClass.value
        console.log("Create Api call")
        console.log(classId)
        console.log(initValues)

        let teachedIdList=[]

        if(selectedTeacher!==null){
            if(selectedTeacher.length>0){
                selectedTeacher.forEach((item)=>{
                    teachedIdList.push(item.value)                     
                })
                }
        }

        let classTeachedIdList=[]

        if(selectedClassTeacher!==null){
            if(selectedClassTeacher.length>0){
                selectedClassTeacher.forEach((item)=>{
                    classTeachedIdList.push(item.value)                     
                })
                }
        }

        createDevision({"school_id":id.schoolId,"class_id":classId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"teacher_ids":teachedIdList,"class_teacher_ids":classTeachedIdList,"cover_image":selectedCovorFile,"logo_image":selectedLogoFile}).then((resposnse)=>{
            props.isLoader(false);
            console.log(resposnse);
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });        
    }

    const updateApiCall = (initValues) =>{
        props.isLoader(true)
        let classId=selectedOptionClass.value
        console.log("Edit Api call")
        console.log(initValues)

        let teachedIdList=[]

        if(selectedTeacher!==null){
            if(selectedTeacher.length>0){
                selectedTeacher.forEach((item)=>{
                    teachedIdList.push(item.value)                     
                })
                }
        }

        let classTeachedIdList=[]

        if(selectedClassTeacher!==null){
            if(selectedClassTeacher.length>0){
                selectedClassTeacher.forEach((item)=>{
                    classTeachedIdList.push(item.value)                     
                })
                }
        }

        console.log("Edit Api call REquest",{"division_id":devisionId,"class_id":classId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"teacher_ids":teachedIdList,"class_teacher_ids":classTeachedIdList,"cover_image":selectedCovorFile,"logo_image":selectedLogoFile})

        editDevision({"division_id":devisionId,"class_id":classId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"teacher_ids":teachedIdList,"class_teacher_ids":classTeachedIdList,"cover_image":selectedCovorFile,"logo_image":selectedLogoFile}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });       
    } 

    const getDevisionDetailsApi = (list)=>{
        getDevisionDetails({"division_id":devisionId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setFormValues({nameEN:resposnse.data.data.name,nameAR:resposnse.data.data.ar_name,nameTU:resposnse.data.data.tu_name});
                
                    if(resposnse.data.data.logo_image===null || resposnse.data.data.logo_image===undefined){
                        resposnse.data.data.logo_image=""
                    }
                    
                    if(resposnse.data.data.cover_image===null || resposnse.data.data.cover_image===undefined){
                        resposnse.data.data.cover_image=""
                    }

                    let logo=resposnse.data.data.logo_image.replace(getMainBucketPath(),"").replace(getDivisionLogoPath(),"")
                    let coverImage=resposnse.data.data.cover_image.replace(getMainBucketPath(),"").replace(getDivisionCoverPath(),"")
                    $('#schoolLogo').next(".custom-file-label").html(logo);
                    $('#schoolCover').next(".custom-file-label").html(coverImage);

                    if(!(logo ==="")){
                        $('#schoolLogo').prop('required',false);
                    }
                    if(!(coverImage ==="")){
                        $('#schoolCover').prop('required',false);
                    }

                    setSelectedLogoFile(logo)
                    setSelectedCovorFile(coverImage)

                setSelectionClassEdit(list,resposnse.data.data.class_id)
                getTeacherListingApi(resposnse.data.data.teacher_list)
            }else{
                navigate(-1)
            }
        })
    }

    const getTeacherListingApi = (listSelectTeacher)=>{
        getUserList({"user_type":"teacher","page":"1","limit":"1000","sort_by":"id","order_by":"desc","school_id":schoolId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })
                setTeacherAndClassTeacherSelection(listSelectTeacher,list)
                setTeacherList(list);
            }else{
                setTeacherList([]);
            }
        })

    }

    useEffect(() => {
        let mounted = true;
        console.log(schoolId)
        getClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":schoolId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){

                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log("hi!");
                    console.log(item);
                    list.push({label:item.name,value:item.id})
                })
                setClasslList(list)
                setSelectedOptionClass(list[0])

                if(props.type=="e"){
                     getDevisionDetailsApi(list)
                 }else{
                    getTeacherListingApi([])
                 }
            }else{
                navigate(-1)
                setClasslList([])
            }
        })

        return () => mounted = false;
    }, []);

    const setSelectionClassEdit = (list,classId) => {
        console.log("Select Class Id");
        console.log(classId);
        console.log(list);
        let selectData =list.filter((item)=> {return item.value === classId})
        console.log(selectData);
        setSelectedOptionClass(selectData[0])
    }

    const setTeacherAndClassTeacherSelection =(listSelectTeacher,listData)=>{

        let selectTeacherList=[]
        let selectClassTeacherList=[]

        listSelectTeacher.forEach((item)=>{

            listData.forEach((subItem)=>{

                if(subItem.value===item.teacher_id){
                    if(item.role==="teacher"){
                        selectTeacherList.push(subItem)
                    }else{
                        selectClassTeacherList.push(subItem)
                    }
                }

            })

        })

        setSelectedClassTeacher(selectClassTeacherList  )
        setSelectedTeacher(selectTeacherList)

    }

    const getSelectValueTeacher =()=>{
        let selectData=""
        if(selectedTeacher!=null){
            if(selectedTeacher.length>0){
                selectedTeacher.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }

    const getSelectValueClassTeacher =()=>{
        let selectData=""
        if(selectedClassTeacher!=null){
            if(selectedClassTeacher.length>0){
                selectedClassTeacher.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }

    $(document).ready(function() {
        $('input[type="file"]').on("change", function() {
          let filenames = [];
          let files = this.files;
          if (files.length > 1) {
            filenames.push("Total Files (" + files.length + ")");
          } else {
            for (let i in files) {
              if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
              }
            }
          }
          $(this)
            .next(".custom-file-label")
            .html(filenames.join(","));
        });
      });

    if ( myClassList==null) return null;

  return (
    <section className="content">
    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>{ (props.type == "e") ? "Edit Devision" : "Create Devision"}</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#" >
                            <label htmlFor="name_en">Select Class</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedOptionClass}
                                                    onChange={handleClassChange}
                                                    options={myClassList}
                                                    name="selectClassList"
                                                 />
                                    <input name="classSelect" className='dropHideInput' value={ (selectedOptionClass == null)? "" : ""+selectedOptionClass.value  } id="classSelect" type="text" required/>            

                            </div>
                            <label htmlFor="name_en">Devision Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text" placeholder='Devision Name' className="form-control" required id="nameEN" name="nameEN" value={formValues.nameEN} onChange={handleChange}/>
                                </div>
                            </div>
                            <label htmlFor="name_ar">Devision Name Arabic</label>
                            <div className="form-group form-float">
                                <div className="form-line">
                                    <input type="text" placeholder='Devision Name Arabic' className="form-control" required id="nameAR" name="nameAR" value={formValues.nameAR} onChange={handleChange} />
                                </div>
                            </div>
                            <label htmlFor="name_tu">Devision Name Turkish</label>
                            <div className="form-group form-float">
                                <div className="form-line">
                                    <input type="text" placeholder='Devision Name Turkish' className="form-control" required id="nameTU" name="nameTU" value={formValues.nameTU} onChange={handleChange}/>
                                </div>
                            </div>

                            <label htmlFor="school_log">Upload Logo</label>
                            <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" name="files[]"  className="custom-file-input form-control" id="schoolLogo" required onChange={handleFileLogoInput} accept="image/*"/>
                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                    </div>
                            </div>

                            <label htmlFor="school_log">Cover Image</label>
                            <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" name="filesCover[]"  className="custom-file-input form-control" id="schoolCover" required onChange={handleFileCoverInput} accept="image/*"/>
                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                    </div>
                            </div>


                            <label htmlFor="name_en">Select Teacher</label>
                                    <div className="form-group">
 
                                    <Select
                                                   isMulti
                                                   value={selectedTeacher}
                                                    onChange={handleTeacherChange}
                                                    options={myTeachertist}
                                                    name="selectTeacher" 
                                    />

                                    <input name="selectTeacher" className='dropHideInput' value={ (selectedTeacher == null)? "" : getSelectValueTeacher()  } id="selectTeacher" type="text" required/>            
                                    </div>

                                    <label htmlFor="name_en">Select Class Teacher</label>
                                    <div className="form-group">
 
                                    <Select
                                                   isMulti
                                                   value={selectedClassTeacher}
                                                    onChange={handleClassTeacherChange}
                                                    options={myTeachertist}
                                                    name="selectClassTeacher" 
                                    />

                                    <input name="selectClassTeacher" className='dropHideInput' value={ (selectedClassTeacher == null)? "" : getSelectValueClassTeacher()  } id="selectClassTeacher" type="text" required/>            
                                    </div>

                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
