export const schoolColumnMapping = (columnNames) => {
    const columnNamesMap = new Map();

    columnNames.forEach((columnName) => {
        const columnNameSmall = columnName.toLowerCase();
        if (columnNameSmall.includes("name")) {
            columnNamesMap.set(columnName, "name");
            return;
        } else if (columnNameSmall.includes("email")) {
            columnNamesMap.set(columnName, "email");
            return;
        } else if (columnNameSmall.includes("phone")) {
            columnNamesMap.set(columnName, "phone_number");
            return;
        } else if (columnNameSmall.includes("website")) {
            columnNamesMap.set(columnName, "website_url");
            return;
        } else if (
            columnNameSmall.includes("country") &&
            columnNameSmall.includes("code")
        ) {
            columnNamesMap.set(columnName, "country_code");
            return;
        } else if (
            columnNameSmall.includes("location") ||
            columnNameSmall.includes("address")
        ) {
            columnNamesMap.set(columnName, "location");
            return;
        } else {
            columnNamesMap.set(columnName, undefined);
        }
    });

    return columnNamesMap;
};

export const teacherColumnMapping = (columnNames) => {
    const columnNamesMap = new Map();

    columnNames.forEach((columnName) => {
        const columnNameSmall = columnName.toLowerCase();
        if (
            columnNameSmall.includes("name") &&
            !columnNameSmall.includes("username")
        ) {
            console.log(columnNameSmall, columnName);
            columnNamesMap.set(columnName, "name");
            return;
        } else if (columnNameSmall.includes("email")) {
            columnNamesMap.set(columnName, "email");
            return;
        } else if (columnNameSmall.includes("phone")) {
            columnNamesMap.set(columnName, "phone_number");
            return;
        } else if (columnNameSmall.includes("username")) {
            columnNamesMap.set(columnName, "username");
            return;
        } else if (columnNameSmall.includes("password")) {
            columnNamesMap.set(columnName, "password");
            return;
        } else {
            columnNamesMap.set(columnName, undefined);
        }
    });

    return columnNamesMap;
};

export const divisionColumnMapping = (columnNames) => {
    const columnNamesMap = new Map();

    columnNames.forEach((columnName) => {
        const columnNameSmall = columnName.toLowerCase();
        if (
            columnNameSmall.includes("class") &&
            columnNameSmall.includes("teacher")
        ) {
            columnNamesMap.set(columnName, "class_teacher");
            return;
        } else if (columnNameSmall.includes("class")) {
            columnNamesMap.set(columnName, "class_name");
            return;
        } else if (columnNameSmall.includes("division")) {
            columnNamesMap.set(columnName, "name");
            return;
        } else if (columnNameSmall.includes("teacher")) {
            columnNamesMap.set(columnName, "teacher");
            return;
        } else {
            columnNamesMap.set(columnName, undefined);
        }
    });

    return columnNamesMap;
};


export const studentColumnMapping = (columnNames) => {
    const columnNamesMap = new Map();

    columnNames.forEach((columnName) => {
        const columnNameSmall = columnName.toLowerCase();
        if (columnNameSmall.includes("username")
        ) {
            columnNamesMap.set(columnName, "username");
            return;
        } else if (columnNameSmall.includes("class")) {
            columnNamesMap.set(columnName, "class");
            return;
        } else if (columnNameSmall.includes("division")) {
            columnNamesMap.set(columnName, "division");
            return;
        } else if (columnNameSmall.includes("name")) {
            columnNamesMap.set(columnName, "name");
            return;
        } else if (columnNameSmall.includes("password")) {
            columnNamesMap.set(columnName, "password");
            return;
        } else {
            columnNamesMap.set(columnName, undefined);
        }
    });

    return columnNamesMap;
};