import React, {useEffect, useState, useCallback} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import {getSchoolList,getClassList,getDevisionList,createBulkUser} from '../../api/apiHandler'
import $ from 'jquery';
import Alert from '../../components/Alert/Alert';
import Option from '../../components/Option/Option';
import Select from 'react-select';

export default function CreateStudentBulk(props) {

    let navigate = useNavigate();
    let { schoolId } = useParams();



    const [schoolList, setSchoolList] = useState([])
    const [classAndDevisionList, setClassAndDevisionList] = useState([])

    const [classList, setClasslList] = useState([])
    const [devisionList, setDevisionList] = useState([])
 
    const [selectedOptionSchool, setSelectedOption] = useState(null);
    const [selecteClassDevisiondOptionSchool, setClassDevisionOption] = useState(null);

    let id = useParams()

    const initalValue = {userNamePrefix: "", noOfStudent: ""}
    const [formValues, setFormValues] = useState(initalValue)

    const [msg, setMsg] = useState(null);
    const showAlert = (msgDisplay, type) => {
        setMsg({
            msg: msgDisplay,
            type: type
        })
    }

    const handleSchoolChange =(data)=>{
        console.log(data)
        if(selectedOptionSchool !==data){
            props.isLoader(true)
            setSelectedOption(data)
            geClassListApi(data.value)
        }
    }
    const handleClassDiviosnChange=(data)=>{
        console.log(data)
        setClassDevisionOption(data)
    }


    const handleChange = (e) => {
        if (e.target.value.trim() == "") {
            e.target.value = e.target.value.trim()
        }
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const handleChangePassword = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const clickSubmitButton = (e) => {
        e.preventDefault();
        console.log(formValues)
        createApiCall(formValues)
    }

    const createApiCall = (initValues) => {
        console.log("Create Api Call")
        props.isLoader(true)
        let schoolId=""

        let classId=""
        let devisionId=""

        if(selectedOptionSchool!==null){
            schoolId=selectedOptionSchool.value
        }

        if(selecteClassDevisiondOptionSchool!==null){
            classId=selecteClassDevisiondOptionSchool.value.split(",")[0]
            devisionId=selecteClassDevisiondOptionSchool.value.split(",")[1]
        }

        let apiData={"school_id":schoolId,"class_id":classId,"division_id":devisionId,"prefix":initValues.userNamePrefix,"no_of_student":initValues.noOfStudent}

        console.log(apiData)

        createBulkUser(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });        
    }


    const geClassListApi = (id)=>{
        getClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            
            if(resposnse.data.code==1){
                setClasslList(resposnse.data.data.list);
                geDevisionListApi(id,resposnse.data.data.list)
            }else{
                setClasslList([])
                geDevisionListApi(id,[])
            }
        })
    }

    const geDevisionListApi = (id,classListData)=>{
        getDevisionList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            if(resposnse.data.code==1){
                setDevisionList(resposnse.data.data.list);
                setDataGroup(classListData,resposnse.data.data.list)
            }else{
                setDevisionList([]);
                setDataGroup([],[])
            }
        })
    }

    const setDataGroup = (classListData,devisionListData)=>{
        console.log("classdivision",classListData, devisionListData);
        let groupData=[]
        classListData.forEach( (item)=>{
            
            let options=[]
            devisionListData.filter( devision => devision.class_id===item.id).forEach((subItem)=>{
                options.push({label:subItem.name,value:item.id+","+subItem.id})
            })

            if(options.length>0){
                var data={label:item.name,options:options}
                groupData.push(data) 
            }
        })

        if(groupData.length>0){
            var selectData=groupData[0].options[0]
            setClassAndDevisionList(groupData)
            setClassDevisionOption(selectData)
        }else{
            setClassAndDevisionList(groupData)
            setClassDevisionOption(null)
        }

        props.isLoader(false)
    }

    const getSchoolListApi = ()=>{
        getSchoolList({"page": "1", "limit": "1000", "sort_by": "id", "order_by": "asc"})
        .then((resposnse) => {
            if (resposnse.data.code == 1) {
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })
                setSchoolList(list)
                
                if(schoolId === undefined || schoolId === null){
                    setSelectedOption(list[0])
                    geClassListApi(list[0].value)    
                }else{

                    let selectData =list.filter((item)=> {return item.value == schoolId})
                    setSelectedOption(selectData[0])
                    geClassListApi(selectData[0].value)    
                }

            } else {
                navigate(-1)
            }
        });
    }

    useEffect(() => {
        props.isLoader(true)
        getSchoolListApi()
    }, []);

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card m-t-15">
                            <div className="header">
                                <h2>Create Bulk Student</h2>
                            </div>
                            <div className="body">
                                <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="user_name">Username Prefix</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Username Prefix' className="form-control"
                                                   id="userNamePrefix" name="userNamePrefix" required value={formValues.userNamePrefix}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="phone_number">No Of Student Create</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="number" placeholder='No Of Student Create' className="form-control"
                                                   id="noOfStudent" name="noOfStudent" required
                                                   value={formValues.noOfStudent} onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <label htmlFor="name_en">Select School</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedOptionSchool}
                                                    onChange={handleSchoolChange}
                                                    options={schoolList}
                                                    name="selectSchoolList"
                                                 />
                                      <input name="school" className='dropHideInput' value={ (selectedOptionSchool == null)? "" : ""+selectedOptionSchool.value  } id="school" type="text" required/>            

                                    </div>

                                    <label htmlFor="name_en">Select Class And Devision</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selecteClassDevisiondOptionSchool}
                                                    onChange={handleClassDiviosnChange}
                                                    options={classAndDevisionList}
                                                    name="selectClassAndDevisionList"
                                                 />
                                     <input name="classAndDevision"  className='dropHideInput' value={ (selecteClassDevisiondOptionSchool == null)? "" : ""+selecteClassDevisiondOptionSchool.value  } id="classAndDevision" type="text" required/>            

                                    </div>

                                    <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}