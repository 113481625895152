import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {createClass,getClassDetails,editClass} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';

export default function CreateClass(props) {
    let id =  useParams()
    let navigate = useNavigate(); 


    const initalValue= {nameEN:"",nameAR:"",nameTU:""}
    const [formValues,setFormValues]= useState(initalValue)
    const [selecteClassOption, setSelectClassOption] = useState(null);
    const [classList, setClasslList] = useState([])
    
    const [msg,setMsg] = useState (null);

    const showAlert = (msgDisplay,type) =>{
        setMsg({
            msg:msgDisplay,
            type:type
        })

    }

    const handleClassChange=(data)=>{
        data = data.some(option => option.value === 0) ? classList : data;
        console.log("Selecting list", data); 
        setSelectClassOption(data)
    }

    const handleChange = (e)=>{
        if(e.target.value.trim()==""){
            e.target.value=e.target.value.trim()      
        }
        const {name,value} =e.target;
        setFormValues({...formValues,[name]:value});
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();

    if(props.type=="e"){
        updateApiCall(formValues)
    }else{
        createApiCall(formValues)
    }

    }

    const createApiCall = (initValues) =>{
        props.isLoader(true)
        console.log("Create Api call")
        console.log(initValues)
        let classIdList=[]

        if(selecteClassOption!==null){
            if(selecteClassOption.length>0){
                selecteClassOption.forEach((item)=>{
                    classIdList.push(item.value)                     
                })
                }
        }

        let permanent_classes=classIdList.join(",")

        createClass({"school_id":id.schoolId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU, "permanent_classes":permanent_classes}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });        
    }

    const updateApiCall = (initValues) =>{
        props.isLoader(true)
        console.log("Edit Api call")
        console.log(initValues)
        let classIdList=[]

        if(selecteClassOption!==null){
            if(selecteClassOption.length>0){
                selecteClassOption.forEach((item)=>{
                    classIdList.push(item.value)                     
                })
                }
        }

        let permanent_classes=classIdList.join(",")
        editClass({"class_id":id.classId,"name":initValues.nameEN,"ar_name":initValues.nameAR,"tu_name":initValues.nameTU,"permanent_classes":permanent_classes}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });        
    } 

    const getClasses = [
        {
            "label": "Select Class",
            "value": 0
        },
        {
            "label": 1,
            "value": 1
        },
        {
            "label": 2,
            "value": 2
        },
        {
            "label": 3,
            "value": 3
        },
        {
            "label": 4,
            "value": 4
        },
        {
            "label": 5,
            "value": 5
        },
        {
            "label": 6,
            "value": 6
        },
        {
            "label": 7,
            "value": 7
        },
        {
            "label": 8,
            "value": 8
        },
        {
            "label": 9,
            "value": 9
        },
        {
            "label": 10,
            "value": 10
        }
    ]

    useEffect(() => {
        let mounted = true;
        if(props.type=="e"){
            console.log("Edit")
            console.log(id)
            getClassDetails({"class_id":id.classId}).then((resposnse)=>{
                console.log(resposnse)
                if(resposnse.data.code==1){
                    console.log({nameEN:resposnse.data.name,nameAR:resposnse.data.data.ar_name,nameTU:resposnse.data.data.tu_name});
                    setFormValues({nameEN:resposnse.data.data.name,nameAR:resposnse.data.data.ar_name,nameTU:resposnse.data.data.tu_name});
                    let list =[]
                    let all_classes = (resposnse.data.data.permanent_classes).split(",");
                    all_classes.map((item, index) => {
                        list.push({label:item,value:item})
                    })
                    setSelectClassOption(list);
                    console.log(formValues)                    
                }else{
                    navigate(-1)
                }
            });        
        }
        return () => mounted = false;
    }, []);


  return (
    <section className="content">
        <Alert alert={msg}/>      

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>{ (props.type == "e") ? "Edit Class" : "Create Class"}</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                            <label htmlFor="name_en">Class Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text"  placeholder="Class Name" className="form-control" id="nameEN" name="nameEN" required value={formValues.nameEN} onChange={handleChange}/>
                                </div>
                            </div>
                            <label htmlFor="name_ar">Class Name Arabic</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text" placeholder="Class Name Arabic" className="form-control" id="nameAR" name="nameAR" required value={formValues.nameAR} onChange={handleChange}/>
                                </div>
                            </div>
                            <label htmlFor="name_tu">Class Name Turkish</label>
                            <div className="form-group ">
                                <div className="form-line">
                                    <input type="text" placeholder="Class Name Turkish" className="form-control" id="nameTU" name="nameTU" required value={formValues.nameTU} onChange={handleChange}/>
                                </div>
                            </div>
                            <label htmlFor="name_en">Select Class</label>
                            <div className="form-group">
                                <Select
                                    isMulti
                                    value={selecteClassOption}
                                    onChange={handleClassChange}
                                    options={getClasses}
                                    name="selectClass" 
                                />       
                            </div>
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
